export default [
  {
    header: 'Imports',
    icon: 'DownloadIcon',
  },
  {
    title: 'Import',
    route: 'import',
    icon: 'DownloadIcon',
  },
]

export default [
  {
    header: 'Catalogue',
    icon: 'PackageIcon',
  },
  {
    title: 'Inventaire',
    route: 'products',
    icon: 'PackageIcon',
  },
  {
    title: 'BOM',
    route: 'bom-list',
    icon: 'ListIcon',
  },
  {
    title: 'Fournisseurs',
    route: 'suppliers',
    icon: 'UsersIcon',
  },
]

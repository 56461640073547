export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Mensuel',
        route: 'dashboard-mensuel',
      },
      {
        title: 'Trimestriel',
        route: 'dashboard-trimestriel',
      },
    ],
  },
]

export default [
  {
    header: 'Ventes',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Clients',
    route: 'customers',
    icon: 'UserCheckIcon',
  },
]
